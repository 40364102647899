<template>
  <b-modal
    v-can="'RepMedReg2' || 'RepMedReg4'"
    id="rule-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title" v-if="rule && rule.id">
        Alterar regra de repasse
      </div>
      <div class="modal-title" v-else>Adicionar nova regra de repasse</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="3">
          <b-form-group>
            <label for="rule_number">Nº da regra</label>
            <b-input
              id="rule_number"
              autocomplete="off"
              readonly
              :value="'Regra #'+ form.number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="9">
          <b-form-group>
            <label for="rule_name">Nome da regra</label>
            <b-input
              id="rule_name"
              autocomplete="off"
              placeholder="Descrever"
              v-model="form.name"
            />
            <div v-if="validated && !form.name" class="custom-invalid-feedback text-right">
              Campo obrigatório
            </div>
            <div v-if="validated && duplicateName" class="custom-invalid-feedback text-right">
              Este nome de regra já existe, tente um nome diferente
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />

      <div class="wrapper-button">        
        <b-button
          variant="outline-danger"
          size="lg"
          @click="closeModal"
        >
          Cancelar
        </b-button>

        <b-button
          variant="primary"
          v-if="rule && rule.id"
          size="lg"
          @click="updateRule"
        >
          Atualizar regra
        </b-button>
        <b-button variant="primary" size="lg" v-else @click="createRule">
          Criar regra
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import api from '@/modules/onlending/api.js'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'RuleModal',
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  props: {
    rule: {
      type: Object,
      default: () => ({})
    },
    rules: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      form: this.defaultForm(),
      validated: false,
      duplicateName: false,

      transferDefinitions: ['Equipe vinculada ao procedimento']
    }
  },
  methods: {
    isValidForm() {
      this.validated = true
      const rul = this.rules.find(option => (option.name.trim()).toLowerCase() === (this.form.name.trim()).toLowerCase())
      if(rul && rul.id !== this.rule.id){
        this.duplicateName = true
        return
      }

      return Boolean(this.form.name)
    },
    async createRule() {
      if (!this.isValidForm()) return
      const loading = this.$loading.show()
      try {
        this.form.active = true
        await api.createOnlendingRule({
          ...this.form,
          clinic_id: this.clinic.id
        })
        this.$emit('createRule')
        this.$toast.success('Regra criada com sucesso!')
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async updateRule() {
      if (!this.isValidForm()) return
      const loading = this.$loading.show()
      try {
        await api.updateOnlendingRule(this.rule.id, {
          ...this.form,
          clinic_id: this.clinic.id
        })
        this.$emit('updateRule')
        this.$toast.success('Regra atualizada com sucesso!')
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    defaultForm() {
      return {
        number: null,
        name: '',
        definition: '',
      }
    },
    closeModal() {
      this.form = this.defaultForm()
      this.$emit('closeModal')
      this.validated = false
      this.$bvModal.hide('rule-modal')
    }
  },
  watch: {
    rule: {
      handler() {
        this.form = this.rule
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#rule-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: space-between;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>